<template>
  <div>
    <div class="tabbar_box">
      <div class="tabbar_item" @click="change_tab(0)">
        <div class="icon_item">
          <i
            class="active_icon iconfont t-icon-shouye-dianji"
            v-if="active == 'home'"
          ></i>
          <i class="iconfont icon-shouye-weidianji1" v-else></i>
        </div>

        <div class="txt">首页</div>
      </div>

      <div class="tabbar_item" @click="change_tab(2)">
        <div class="icon_item">
          <i
            class="active_icon iconfont t-icon-wode-dianji"
            v-if="active == 'user'"
          ></i>
          <i class="iconfont icon-wode-weidianji1" v-else></i>
        </div>

        <div class="txt">我的</div>
      </div>

      <div class="img_box" @click="change_tab(1)">
        <div class="dian_img">
          <i class="iconfont icon-saoma"></i>
        </div>
        <div class="txt">扫码充电</div>
      </div>
    </div>

    <!-- <van-tabbar v-model="tab_active" @change="onChange">
      <van-tabbar-item>
        <template>
          <div class="tabbar_item">
            <div class="icon_item">
              <i class="iconfont icon-shouye-weidianji1"></i>
              <i class="active_icon iconfont t-icon-shouye-dianji"></i>
            </div>

            <div class="txt">首页</div>
          </div>
        </template>
      </van-tabbar-item>

      <van-tabbar-item @click="scan_code()">
        <template>
          <div class="img_box">
            <div class="dian_img">
              <i class="iconfont icon-saoma"></i>
            </div>
            <div class="txt">扫码充电</div>
          </div>
        </template>
      </van-tabbar-item>

      <van-tabbar-item>
        <template>
          <div class="tabbar_item">
            <div class="icon_item">
              <i class="iconfont icon-wode-weidianji1"></i>
              <i class="active_icon iconfont t-icon-wode-dianji"></i>
            </div>

            <div class="txt">我的</div>
          </div>
        </template>
      </van-tabbar-item>

    </van-tabbar> -->
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getData } from "@/api/user";
import { _debounce } from "@/libs/util";

export default {
  props: ["active"],
  data() {
    return {
      tab_active: this.active,
    };
  },
  created() {},
  methods: {
    // onChange: function (index) {
    //   switch (index) {
    //     case 0:
    //       console.log(this.active);
    //       if(this.active == 'home') return
    //       this.$router.push({ name: "home" });
    //       break;

    //     case 1:
    //       console.log("扫码充电");
    //       this.scan_code();
    //       break;

    //     case 2:
    //       if(this.active == 'user') return
    //       this.$router.push({ name: "user" });
    //       break;

    //     default:
    //       break;
    //   }
    //   return;
    // },
    change_tab: function (index) {
      switch (index) {
        case 0:
          console.log(this.active);
          if (this.active == "home") return;
          this.$router.push({ name: "home" });
          break;

        case 1:
          console.log("扫码充电");
          this.scan_code();
          break;

        case 2:
          console.log(this.active);
          if (this.active == "user") return;
          this.$router.push({ name: "user" });
          break;

        default:
          break;
      }
      return;
    },
    scan_code: _debounce(function () {
      let url = "";
      let ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        url = location.href.split("#")[0];
      } else if (/android/.test(ua)) {
        url = location.href;
      }
      getData({
        url: "api/get_js_config",
        data: {
          url,
          // url: window.location.href.split("#")[0],
        },
      }).then((res) => {
        console.log(res);
        let data = res.data.data;
        wx.config({
          debug: false,
          appId: data.appId + "",
          timestamp: data.timestamp,
          nonceStr: data.nonceStr + "",
          signature: data.signature + "",
          jsApiList: ["checkJsApi", "scanQRCode"], // 必填,需要使用的JS接口列表
        });
        wx.error(function (res) {
          alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });

        wx.ready(function () {
          wx.checkJsApi({
            jsApiList: ["scanQRCode"],
            success: function (res) {
              // 以键值对的形式返回，可用true，不可用false。如：{"checkResult":{"scanQRCode":true},"errMsg":"checkJsApi:ok"}
              if (res.checkResult.scanQRCode === true) {
                wx.scanQRCode({
                  // 微信扫一扫接口
                  desc: "scanQRCode desc",
                  needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function (res) {
                    console.log(res);
                  },
                });
              } else {
                alert("抱歉，当前客户端版本不支持扫一扫");
              }
            },
            fail: function (res) {
              // 检测getNetworkType该功能失败时处理
              alert("检测getNetworkType该功能失败" + res);
            },
          });
        });
        /* 处理失败验证 */
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          alert("配置验证失败: " + res.errMsg);
        });
      });
    }),
  },
  // beforeRouteEnter(to, from, next) {
  //   var u = navigator.userAgent;

  //   var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

  //   // XXX: 修复iOS版微信HTML5 History兼容性问题

  //   if (isiOS && to.path !== location.pathname) {
  //     // 此处不可使用location.replace

  //     location.assign(to.fullPath);
  //   } else {
  //     next();
  //   }
  // },
};
</script>

<style lang="less" scoped>
.tabbar_box {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 8px 60px 0;
  width: 100vw;
  height: 68px;
  background: #fff;
  // padding-bottom: constant(safe-area-inset-bottom);
  // padding-bottom: env(safe-area-inset-bottom);
}

.tabbar_item {
  text-align: center;

  .icon_item {
    display: flex;
    align-items: center;
    justify-content: center;

    .iconfont {
      font-size: 22px;

      display: inline-block;
      width: 22px;
      height: 22px;
      background-repeat: no-repeat;
    }

    // .active_icon {
    //   display: none;
    // }
  }

  .txt {
    margin-top: 5px;
    color: #333;
    font-size: 12px;
    font-size: 400;
  }
}

// 二维码 扫一扫
.img_box {
  text-align: center;
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translateX(-50%);
  height: 80px;

  .dian_img {
    margin-bottom: 10px;
    width: 68px;
    height: 68px;
    line-height: 68px;
    text-align: center;
    background: #3dc48d;
    border-radius: 50%;
    overflow: hidden;

    .iconfont {
      color: #fff;
      font-size: 37px;
    }
  }

  .txt {
    color: #333;
    font-size: 12px;
    font-weight: 600;
  }
}
// /deep/ .van-tabbar-item {
//   z-index: 9999;
// }

// .van-tabbar {
//   height: 62px;
// }

// .van-tabbar-item--active {
//   color: #3dc48d;
//   background-color: transparent;

//   .van-tabbar-item__text {
//     .tabbar_item {
//       .txt {
//         color: #3dc48d;
//         font-weight: 600;
//       }

//       .iconfont {
//         display: none !important;
//       }

//       // .active_icon {
//       //   display: block !important;
//       // }
//     }
//   }
// }
</style>