<!-- 首页 -->
<template>
  <div class="home">
    <div v-if="ismap">
      <div class="search-box search-map d-flex">
        <van-search
          class="search"
          v-model="map_val"
          shape="round"
          placeholder="请输入地址或设备号"
          background="transparent"
          @input="map_input"
        />
        <div class="switch" @click="change(2)">
          <i class="iconfont t-icon-liebiao"></i>
          <p>列表</p>
        </div>
      </div>
      <div id="map" style="width: 100vw; height: calc(100vh - 60px)"></div>
    </div>

    <div v-else>
      <pull-refresh :refreshing="isRefreshing" :on-refresh="onRefresh" pull-height="100vh">
        <div class="search-box d-flex" @click="search()">
          <van-search
            class="search"
            v-model="list_val"
            shape="round"
            placeholder="请输入地址或设备号"
            @input="list_input"
            v-if="latitude && longitude"
          />
          <van-search
            class="search"
            v-model="list_val"
            shape="round"
            disabled
            placeholder="请输入地址或设备号"
            @input="list_input"
            v-else
          />
          <div class="switch" @click="change(1)">
            <i class="iconfont t-icon-ditu"></i>
            <p>地图</p>
          </div>
        </div>

        <van-tabs
          v-model="tab"
          color="#3DC48D"
          class="home-tab"
          @change="change_tab"
        >
          <van-tab title="附近设备">
            <div v-if="isadr">
              <div class="home-tab-list" v-if="nearby_show">
                <div
                  class="home-tab-item"
                  v-for="item in nearby_list"
                  :key="item.id"
                  @click="to_detail(item, 1)"
                >
                  <h3>{{ item.location }}</h3>
                  <p class="addr">
                    <i class="iconfont t-icon-dingwei-1"></i
                    ><span>{{ item.address_detail }}</span>
                  </p>
                  <p class="num">
                    编号：{{ item.id }} <i class="line"></i> 可用端口：<span
                      class="port"
                      >{{
                        item.charger_port && item.charger_port["0"]
                          ? item.charger_port["0"]
                          : "无"
                      }}</span
                    >
                  </p>
                  <div class="mile open" @click.stop="open(item)">
                    {{ item.distance }}
                    <i class="iconfont t-icon-a-dingweijiantou"></i>
                  </div>
                </div>
              </div>
              <div class="no-list" v-else>
                <img
                  class="no-img"
                  src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101755.png"
                />
                <p class="no-txt">附近没有设备哦~</p>
              </div>
            </div>
            <div class="no-list" v-else>
              <img
                class="no-img"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/E-dw%403x.png"
              />
              <p class="no-txt">哎呦～无法显示当前位置哦</p>
              <button class="no-btn" @click="get_wx_location">
                授权当前位置
              </button>
            </div>
          </van-tab>
          <van-tab title="近期使用">
            <div v-if="isadr">
              <div v-if="recent_show" class="home-tab-list">
                <load-more
                  :pageIndex="pageIndex"
                  :pageSize="pageSize"
                  :totalCount="totalCount"
                  @loadmore="loadmore"
                >
                  <div
                    class="home-tab-item"
                    v-for="item in recent_list"
                    :key="item.id"
                    @click="to_detail(item, 2)"
                  >
                    <h3>{{ item.charger ? item.charger.location : "" }}</h3>
                    <p class="addr">
                      <i class="iconfont t-icon-dingwei-1"></i
                      ><span>{{
                        item.charger ? item.charger.address_detail : ""
                      }}</span>
                    </p>
                    <p class="num">
                      编号：{{ item.charger_id }}
                      <i class="line"></i> 可用端口：<span class="port">{{
                        item.charger_port && item.charger_port["0"]
                          ? item.charger_port["0"]
                          : "无"
                      }}</span>
                    </p>
                    <div class="mile open" @click.stop="open(item)">
                      {{ item.distance }}
                      <i class="iconfont t-icon-a-dingweijiantou"></i>
                    </div>
                  </div>
                </load-more>
              </div>
              <div class="no-list" v-else>
                <img
                  class="no-img"
                  src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101755.png"
                />
                <p class="no-txt">近期还未使用过哦～</p>
              </div>
            </div>
            <div class="no-list" v-else>
              <img
                class="no-img"
                src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/E-dw%403x.png"
              />
              <p class="no-txt">哎呦～无法显示当前位置哦</p>
              <button class="no-btn" @click="get_wx_location">
                授权当前位置
              </button>
            </div>
          </van-tab>
        </van-tabs>
      </pull-refresh>
    </div>
    <!-- 正在充电 -->
    <div class="home-charge" v-if="charger_len > 0" @click="to_device()">
      <i class="iconfont icon-chongdianzhong"></i>
      <p class="font">正在充电</p>
    </div>

    <!-- 地图中充电桩 -->
    <div class="home-info" v-if="isinfo">
      <h3>{{ info.location }}</h3>
      <p class="addr">
        <i class="iconfont t-icon-dingwei-1"></i
        ><span>{{ info.address_detail }}</span>
      </p>
      <div class="home-intro">
        <div class="lt">
          <p class="num">
            编号：<span>{{ info.id }}</span>
          </p>
          <p class="num">
            计费：<span
              >1元/{{
                info.village && info.village.hours ? info.village.hours : 4
              }}小时</span
            >
          </p>
        </div>
        <span class="line"></span>
        <div class="rt">
          <p class="num green">
            <i class="dian"></i>闲置端口：<span>{{
              info.charger_port && info.charger_port["0"]
                ? info.charger_port["0"]
                : "无"
            }}</span>
          </p>
          <p class="num">
            <i class="dian"></i>不可使用：<span>{{
              info.charger_port && info.charger_port["2"]
                ? info.charger_port["2"]
                : "无"
            }}</span>
          </p>
          <div class="mile" @click.stop="open(info)">
            <img
              class="img"
              src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/202172-105436.png"
              alt=""
            />
            <p>{{ info.distance }}</p>
          </div>
        </div>
      </div>
      <div class="intro-btn" @click="to_detail_map()">查看详情</div>
    </div>
    <tabbar :active="active" v-if="!ismap"></tabbar>
  </div>
</template>

<script>
import tabbar from "@/components/tabbar.vue";
import { getData } from "@/api/user";
import wx from "weixin-js-sdk";
import pullRefresh from "@/components/pullRefresh.vue";

export default {
  components: {
    tabbar,
    pullRefresh
  },
  data() {
    return {
      isadr: true,
      ismap: false, // 列表还是地图
      isinfo: false, // 地图中的充电桩信息
      active: "home",
      map_val: "",
      list_val: "",
      tab: 0,
      info: {},
      nearby_list: [],
      nearby_show: true,
      // 近期
      recent_list: [],
      recent_show: true,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,

      // 自己坐标
      // latitude: "39.941998", // 纬度
      // longitude: "116.397102", // 经度
      latitude: "", // 纬度
      longitude: "", // 经度
      // 地图中心点
      lat: "", // 纬度
      log: "", // 经度

      charger_len: 0, // 正在充电的个数
      charger_id: 0,

      qq: window.TMap,
      map: null,
      marker: null,
      zoom: 16,

      time: 0,
      time_new: 0,
      time_out: "", // 防抖节流
      isRefreshing : false
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  created() {
    this.get_wx_location();
    // this.nearby();
    this.charger();

    // window.addEventListener("pageshow", function (e) {
    //   // 通过persisted属性判断是否存在 BF Cache
    //   console.log("pageshow>>>>", e);
    //   if (e.persisted) {
    //     console.log(1111);
    //     location.reload();
    //   }
    // });
  },
  methods: {
    search () {
      if (!this.latitude || this.longitude) {
        this.get_wx_location()
      }
    },
    // 地图列表切换
    change(type) {
      if (type == 2) {
        this.isinfo = false;
        this.ismap = false;
      } else {
        if (this.latitude && this.longitude) {
          this.map_val = "";
          this.ismap = true;
          this.$nextTick(() => {
            this.initMap();
          });
        } else {
          this.get_wx_location()
        }
      }
    },
    // -------------列表-----------------------
    list_input(e) {
      if (this.tab == 0) {
        this.nearby(e);
      } else {
        this.pageIndex = 1;
        this.recent(e);
      }
    },
    change_tab(e) {
      this.pageIndex = 1;
      if (e == 0) {
        this.nearby(this.list_val);
      } else {
        this.recent(this.list_val);
      }
    },
    // 附近设备
    nearby(val) {
      getData({
        url: "api/charging/index/nearby_equipment_list",
        data: {
          latitude: this.latitude,
          longitude: this.longitude,
          search_word: val,
        },
      }).then((res) => {
        var data = res.data.data;
        if (res.data.code === 200) {
          this.nearby_list = data.data;
          this.nearby_show = this.nearby_list.length <= 0 ? false : true;
          if (this.nearby_list && this.nearby_list.length > 0) {
            this.nearby_list.forEach((v) => {
              v.mile = this.hasDot(v.distance);
            });
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    hasDot(num) {
      if (!isNaN(num)) {
        return (num + "").indexOf(".") != -1 ? num.toFixed(2) : num;
      }
    },
    // 近期使用
    recent(val) {
      getData({
        url: `api/charging/index/recent_equipment_list?page=${this.pageIndex}`,
        data: {
          latitude: this.latitude,
          longitude: this.longitude,
          search_word: val,
        },
      }).then((res) => {
        var data = res.data.data.data;
        if (res.data.code === 200) {
          this.pageSize = data.per_page;
          this.totalCount = data.total;
          let list = data.data;
          if (list && list.length > 0) {
            list.forEach((v) => {
              v.mile = this.hasDot(v.distance);
              v.location = v.charger && v.charger.location ? v.charger.location : '';
              v.address_detail = v.charger && v.charger.address_detail ? v.charger.address_detail : '';
              v.latitude = v.charger && v.charger.latitude ? v.charger.latitude : '';
              v.longitude = v.charger && v.charger.longitude ? v.charger.longitude : '';
            });
          }
          if (this.pageIndex == 1) {
            this.recent_list = list;
            this.recent_show = list.length <= 0 ? false : true;
          } else {
            this.recent_list = this.recent_list.concat(list);
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    loadmore(pageIndex) {
      this.pageIndex = pageIndex;
      this.recent(this.list_val);
    },
    onRefresh() {
      //刷新前需要先手动将设为true，否则pull-refresh不会监测到状态变化
      this.isRefreshing = true;
      setTimeout(() => {
        this.refresh()
        this.isRefreshing = false;
      }, 2000)
    },
    refresh() {
      this.pageIndex = 1;
      this.recent(this.list_val)
      if(this.isadr) {
        this.nearby(this.list_val);
      }else {
        this.get_wx_location()
      }
    },
    // 正在充电
    charger() {
      getData({
        url: "api/charging/index/current_user_charging",
        data: {},
      }).then((res) => {
        var data = res.data.data;
        if (res.data.code === 200) {
          this.charger_len = data.consume_ids.length;
          this.charger_id = data.consume_ids;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    to_detail(item, num) {
      if(num ==2 && item.charger && item.charger.is_online ==0) {
        this.$toast('该设备暂不可用！')
      }else {
        this.$router.push({
          name: "detail",
          query: {
            id: num == 1 ? item.id : item.charger_id,
          },
        });
      }
      
    },
    to_detail_map() {
      this.$router.push({
        name: "detail",
        query: {
          id: this.info.id,
        },
      });
    },
    // -------------地图-----------------------
    map_input(e) {
      this.tab = 0;
      this.isinfo = false;
      this.ismap = false;
      this.list_val = e;
      this.list_input(e);
    },
    initMap() {
      var center = new this.qq.LatLng(this.latitude, this.longitude);
      this.map = new this.qq.Map("map", {
        center: center, // 设置地图中心点坐标
        zoom: 16, // 设置地图缩放级别
        pitch: 43.5, // 设置俯仰角
        rotation: 45, // 设置地图旋转角度
      });

      this.map.on("zoom", () => {
        this.zoom = this.map.getZoom();
        this.time_new = new Date().getTime();
        if (this.time_new - this.time > 800) {
          this.time = this.time_new;
          this.draw_marker();
        }
      });

      this.map.on("panend", () => {
        let LatLng = this.map.getCenter();
        this.lng = LatLng.lng;
        this.lat = LatLng.lat;
        this.time_new = new Date().getTime();
        if (this.time_new - this.time > 500) {
          this.time = this.time_new;
          this.draw_marker();
        }
      });

      this.map.on("click", () => {
        this.isinfo = false;
      });

      //自己的位置
      new this.qq.MultiMarker({
        id: "marker-layer", //图层id
        map: this.map,
        styles: {
          //点标注的相关样式
          marker: new this.qq.MarkerStyle({
            width: 25,
            height: 44,
            anchor: { x: 16, y: 32 },
            src: "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/202172-101154.png",
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "marker0",
            styleId: "marker",
            position: new this.qq.LatLng(this.latitude, this.longitude),
            properties: {
              title: "marker0",
            },
          },
        ],
      });
      this.lng = this.longitude;
      this.lat = this.latitude;
      this.draw_marker();
    },
    draw_marker() {
      getData({
        url: "api/charging/index/map_charger_list",
        data: {
          latitude: this.lat,
          longitude: this.lng,
          this_latitude: this.latitude,
          this_longitude: this.longitude,
          proportion: this.zoom,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          let data = res.data.data.data;
          if (data && data.length > 0) {
            if (this.marker) {
              data.forEach((v) => {
                v.styleId = "marker";
                v.position = new this.qq.LatLng(v.latitude, v.longitude);
                v.properties = {
                  title: v.id,
                };
                v.mile = this.hasDot(v.distance);
                this.marker.add(v);
              });
            } else {
              data.forEach((v) => {
                v.styleId = "marker";
                v.position = new this.qq.LatLng(v.latitude, v.longitude);
                v.properties = {
                  title: v.id,
                };
                v.mile = this.hasDot(v.distance);
              });
              this.marker = new this.qq.MultiMarker({
                id: `marker-layer1`, //图层id
                map: this.map,
                styles: {
                  //点标注的相关样式
                  marker: new this.qq.MarkerStyle({
                    width: 40,
                    height: 46,
                    anchor: { x: 16, y: 32 },
                    src: "https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/202172-10125.png",
                  }),
                },
                geometries: data,
              });
            }

            this.marker.on("click", (e) => {
              this.info = e.geometry;
              this.isinfo = true;
            });
          }
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    open(item) {
      // let backurl = window.location.href.split("#")[0];
      let url = "";
      let ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        url = location.href.split("#")[0];
      } else if (/android/.test(ua)) {
        url = location.href;
      }
      getData({
        url: "api/get_js_config",
        data: {
          url, // url必须是本页面的地址
        },
      }).then((res) => {
        let data = res.data.data;
        var tiem = data.timestamp;
        wx.config({
          debug: false,
          appId: data.appId, // 必填,公众号的唯一标识
          timestamp: tiem, // 必填,生成签名的时间戳
          nonceStr: data.nonceStr, // 必填,生成签名的随机串
          signature: data.signature, // 必填,签名
          jsApiList: ["openLocation"], // 必填,需要使用的JS接口列表
        });
        wx.error(function (res) {
          alert("出错了：" + res.errMsg);
        });

        wx.ready(function () {
          wx.checkJsApi({
            // 判断当前客户端版本是否支持指定JS接口
            jsApiList: ["openLocation"],
            success: function (res) {
              if (res.checkResult.openLocation === true) {
                wx.openLocation({
                  latitude: parseFloat(item.latitude),
                  longitude: parseFloat(item.longitude),
                  name: item.location,
                  address: item.address_detail,
                  scale: 18,
                });
              } else {
                alert("抱歉，当前客户端版本不支持获取地理位置");
              }
            },  
            fail: function (res) {
              // 检测getNetworkType该功能失败时处理
              alert("检测该功能失败" + res);
            },
          });
        });
        /* 处理失败验证 */
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          alert("配置验证失败: " + res.errMsg);
        });
      });
    },
    // 获取坐标
    get_wx_location() {
      let that = this;
      let url = "";
      let ua = navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(ua)) {
        url = location.href.split("#")[0];
      } else if (/android/.test(ua)) {
        url = location.href;
      }

      getData({
        url: "api/get_js_config",
        data: {
          url,
        },
      }).then((res) => {
        let data = res.data.data;
        if(res.data.code == 200) {
          wx.config({
            debug: false,
            appId: data.appId + "",
            timestamp: data.timestamp,
            nonceStr: data.nonceStr + "",
            signature: data.signature + "",
            jsApiList: ["checkJsApi", "getLocation"], // 必填,需要使用的JS接口列表
          });

          wx.error(function (res) {
            that.isadr = false
            alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
          });

          wx.ready(function () {
            // config信息验证成功后会执行ready方法,所有接口调用都必须在config接口获得结果之后
            // config 是一个客户端的异步操作,所以如果需要在页面加载时调用相关接口,则须把相关接口放在ready函数中调用来确保正确执行.对于用户触发是才调用的接口,则可以直接调用,不需要放在ready函数中
            wx.checkJsApi({
              // 判断当前客户端版本是否支持指定JS接口
              jsApiList: ["getLocation"],
              success: function (res) {
                if (res.checkResult.getLocation === true) {
                  wx.getLocation({
                    type: "gcj02", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                    success: function (res) {
                      that.latitude = res.latitude;
                      that.longitude = res.longitude;
                      that.nearby();
                      that.isadr = true
                    },
                    fail:function () {
                      that.isadr = false
                    },
                    cancel:function () {
                      that.isadr = false
                    }
                  });
                } else {
                  that.isadr = false
                  alert("抱歉，当前客户端版本不支持获取地理位置");
                }
              },
              fail: function (res) {
                that.isadr = false
                // 检测getNetworkType该功能失败时处理
                alert("检测该功能失败" + res);
              },
            });
          });
        }else {
          that.isadr = false
        }
      });
    },
    // -------------其他-----------------------
    to_device() {
      if (this.charger_len == 1) {
        this.$router.push({ name: "power", query: { id: this.charger_id[0] } });
      } else {
        this.$router.push({ name: "device" });
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    console.log("to.fullPath>>>>>", to.fullPath);
    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.home {
  .box {
    height: 120px;
    width: 160px;
    background: red;
  }
  .search-box {
    padding-right: 12px;
    background: #fff;
  }

  .search {
    width: 332px;
  }
  .switch {
    height: 40px;
    width: 40px;
    background: #fff;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    color: #333;
    margin: 10px 0;
    .iconfont {
      display: inline-block;
      height: 24px;
      width: 24px;
      background-repeat: no-repeat;
    }
  }
  .search-map {
    background: transparent;
    .van-search__content {
      background: #fff;
    }
    .switch {
      padding: 3px 0 0;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border-radius: 6px;
      .iconfont {
        height: 17px;
        width: 18px;
      }
    }
  }
  .home-tab-list {
    padding: 16px 12px 100px;
  }
  .home-tab-item {
    position: relative;
    margin-bottom: 16px;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;
    h3 {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      color: #333;
      padding-right: 100px;
    }
    .addr {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
      color: #999;
      .iconfont {
        height: 12px;
        width: 12px;
        background-repeat: no-repeat;
        margin-right: 4px;
        margin-top: 3px;
      }
      span {
        flex: 1;
        padding-right: 70px;
      }
    }
    .num {
      display: flex;
      align-items: center;

      font-size: 14px;
      line-height: 20px;
      color: #666;
    }
    .line {
      height: 12px;
      width: 1px;
      margin: 0 18px;
      background: #d0d0d0;
    }
    .port {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #3dc48d;
    }
    .mile {
      display: flex;
      align-items: center;
      position: absolute;
      right: 17px;
      top: 19px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333;
      .iconfont {
        height: 17px;
        width: 17px;
        background-repeat: no-repeat;
        margin-left: 8px;
      }
    }
    .open {
      padding: 19px 17px 11px 5px;
      top: 0;
      right:0;
    }
  }
  
}
.home-charge {
  position: fixed;
  right: 13px;
  bottom: 120px;
  width: 54px;
  height: 54px;
  background: #ffe000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  text-align: center;
  .iconfont {
    position: absolute;
    top: 4px;
    left: 50%;
    transform: translate(-50%);
    font-size: 30px;
    color: #fff;
  }
  .font {
    position: absolute;
    bottom: 0;
    width: 54px;
    height: 18px;
    background: #ffae15;
    opacity: 1;
    border-radius: 0px 0px 10px 10px;
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
    color: #fff;
  }
}
.home-info {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99999999999999;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  padding: 33px 12px 10px;
  font-size: 16px;
  line-height: 16px;
  color: #333;
  h3 {
    font-weight: 600;
    margin-bottom: 4px;
    padding: 0 14px;
    padding-right: 100px;
  }
  .addr {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 20px;
    color: #999;
    padding: 0 14px;
    .iconfont {
      height: 12px;
      width: 12px;
      background-repeat: no-repeat;
      margin-right: 4px;
    }
  }
  .home-intro {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    padding: 0 14px;
  }
  .line {
    height: 42px;
    width: 1px;
    background: #edebeb;
    margin: 0 50px;
  }
  .lt,
  rt {
    flex-direction: column;
    align-items: flex-start;
  }
  .num {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #666;
    margin-bottom: 5px;
    span {
      color: #999;
    }
  }
  .dian {
    width: 4px;
    height: 4px;
    background: #666;
    border-radius: 50%;
    margin-right: 5px;
  }
  .num.green {
    color: #3dc48d;
    .dian {
      background: #3dc48d;
    }
    span {
      color: #3dc48d;
    }
  }
  .mile {
    position: absolute;
    right: 35px;
    top: -30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    color: #333;
    text-align: center;
    .img {
      height: 55px;
      width: 55px;
    }
  }
  .intro-btn {
    margin: 25px 0 20px;
    height: 44px;
    border: 1px solid #3dc48d;
    border-radius: 22px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    color: #3dc48d;
  }
}
</style>